import _api from "./api";
import _configuration from "./configuration";
var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * OpenAI API
 * APIs for sampling from and fine-tuning language models
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
__exportStar(_api, exports);
__exportStar(_configuration, exports);
export default exports;
export const __esModule = exports.__esModule,
  OpenAIApi = exports.OpenAIApi,
  OpenAIApiFactory = exports.OpenAIApiFactory,
  OpenAIApiFp = exports.OpenAIApiFp,
  OpenAIApiAxiosParamCreator = exports.OpenAIApiAxiosParamCreator,
  CreateImageRequestResponseFormatEnum = exports.CreateImageRequestResponseFormatEnum,
  CreateImageRequestSizeEnum = exports.CreateImageRequestSizeEnum,
  ChatCompletionResponseMessageRoleEnum = exports.ChatCompletionResponseMessageRoleEnum,
  ChatCompletionRequestMessageRoleEnum = exports.ChatCompletionRequestMessageRoleEnum,
  Configuration = exports.Configuration;