import _package from "../package.json";
import * as _formData2 from "form-data";
var _formData = "default" in _formData2 ? _formData2.default : _formData2;
var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * OpenAI API
 * APIs for sampling from and fine-tuning language models
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Configuration = void 0;
const packageJson = _package;
class Configuration {
  constructor(param = {}) {
    this.apiKey = param.apiKey;
    this.organization = param.organization;
    this.username = param.username;
    this.password = param.password;
    this.accessToken = param.accessToken;
    this.basePath = param.basePath;
    this.baseOptions = param.baseOptions;
    this.formDataCtor = param.formDataCtor;
    if (!this.baseOptions) {
      this.baseOptions = {};
    }
    this.baseOptions.headers = Object.assign({
      "User-Agent": `OpenAI/NodeJS/${packageJson.version}`,
      "Authorization": `Bearer ${this.apiKey}`
    }, this.baseOptions.headers);
    if (this.organization) {
      this.baseOptions.headers["OpenAI-Organization"] = this.organization;
    }
    if (!this.formDataCtor) {
      this.formDataCtor = _formData;
    }
  }
  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  isJsonMime(mime) {
    const jsonMime = new RegExp("^(application/json|[^;/ \t]+/[^;/ \t]+[+]json)[ \t]*(;.*)?$", "i");
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === "application/json-patch+json");
  }
}
exports.Configuration = Configuration;
export default exports;